/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ColumnHeaderWrap, Image, ColumnContent, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1d4wrro bg--center" name={"picture"} style={{"marginTop":0,"paddingTop":300,"paddingBottom":300}} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/59/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/59/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/59/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/59/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/59/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/59/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/59/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/59/fullscreen_s=3000x_.jpg);
    }
  
      `}>
        </Column>


        <Column className="pb--50 pt--50" name={"about-me"} layout={"l5"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Jiří Stloukal"}>
              </Title>

              <Text className="text-box fs--14 w--500" style={{"maxWidth":380}} content={"<span>Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and much more can be customized.</span>"}>
              </Text>

              <Text className="text-box fs--14 w--500" style={{"maxWidth":380}} content={"<span><a href=\"https://saywebpage.com\">Create your own website</a></span>"}>
              </Text>

              <ColumnHeaderWrap className="flex">
                
                <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={""}>
                </Image>

                <ColumnContent >
                  
                  <Text className="text-box" content={"info@vase-stranky.com"}>
                  </Text>

                </ColumnContent>

              </ColumnHeaderWrap>

              <ColumnHeaderWrap className="flex">
                
                <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={""}>
                </Image>

                <ColumnContent >
                  
                  <Text className="text-box" content={"510-851-4014"}>
                  </Text>

                </ColumnContent>

              </ColumnHeaderWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" action={"/contact"} fields={[{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}